import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Carousel from 'nuka-carousel'

import styles from './general.module.scss'
import Button from '../button'
import Chevron from '../svg/Chevron.svg'
import DiamondSvg from '../svg/Diamond.svg'
import DiamondNoFillSvg from '../svg/DiamondNoFill.svg'
import NSvg from '../svg/N.svg'
import NWSvg from '../svg/NW.svg'
import WSvg from '../svg/W.svg'
import SWSvg from '../svg/SW.svg'
import SSvg from '../svg/S.svg'
import SESvg from '../svg/SE.svg'
import ESvg from '../svg/E.svg'
import NESvg from '../svg/NE.svg'
import CSvg from '../svg/C.svg'
import { useTranslation } from 'next-i18next'
import WTFavourite from '../svg/WTFavourite.svg'
import HotelGuests from '../svg/HotelGuests.svg'
import PrivateUse from '../svg/private.svg'
import IslandResident from '../svg/IslandResidents.svg'
import Community from '../svg/Rating-Community.svg'
import Gold from '../svg/Rating-Gold.svg'
import Green from '../svg/Rating-Green.svg'
import Heritage from '../svg/Rating-Heritage.svg'
import Shining from '../svg/Rating-Shining.svg'
import Visionary from '../svg/Rating-Visionary.svg'
import {
  calculateMarkup,
  formatAmountForDisplay,
} from '../../utils/stripe-helpers'
import { CurrencyContext } from '../../lib/context/CurrencyContext'
import { SettingsContext } from '../../lib/context/SettingsContext'
import { useModal } from '../../lib/context/modal-context'
import OverviewModal from '../popups/overview-modal'
import Image from 'next/image'
import WishlistFavourite from './favourite'

const HotelCard = ({
  id,
  gallery,
  small,
  hotel,
  smallGallery,
  hideActions,
  fullIntro,
  fromPrice,
  landscape,
  showFrom = false,
}) => {
  const { t, i18n } = useTranslation()
  const { currency } = useContext(CurrencyContext)
  const { settings } = useContext(SettingsContext)
  const { setModal } = useModal()
  const cardProps = {
    id,
  }

  const showPopup = () => {
    setModal({
      title: hotel.name,
      content: (
        <OverviewModal
          description={hotel.overview}
          images={hotel.images}
          perNight={0}
          title={hotel.name}
        />
      ),
    })
  }
  return (
    <>
      {hotel && (
        <div
          {...cardProps}
          className={`card hotel ${styles.card}
      ${gallery ? styles.gallery : ''}
      ${smallGallery ? styles.smallGallery : ''}
      ${small ? styles.small : ''}
      ${
        landscape
          ? `landscape ${styles.landscape}`
          : `vertical ${styles.vertical}`
      } ${hotel.basePrice === 0 && showFrom === false ? 'not-available' : ''}`}
        >
          {(hotel.favourite !== '' || hotel.rating !== '') && (
            <div className={styles.favourite}>
              {hotel.favourite === 'Favourite' && <WTFavourite />}
              {hotel.favourite === 'Residents' && <IslandResident />}
              {hotel.favourite === 'Guests' && <HotelGuests />}
              {hotel.favourite === 'Private' && <PrivateUse />}
              {hotel.rating === 'Community' && <Community />}
              {hotel.rating === 'Gold' && <Gold />}
              {hotel.rating === 'Green' && <Green />}
              {hotel.rating === 'Heritage' && <Heritage />}
              {hotel.rating === 'Shining' && <Shining />}
              {hotel.rating === 'Visionary' && <Visionary />}
            </div>
          )}
          {!gallery && hotel.images.length > 0 && (
            <div className={styles.image}>
              {hotel.images[0].image && (
                <>
                  {hotel.images[0].image.formats &&
                    hotel.images[0].image.formats.hasOwnProperty('small') && (
                      <Image
                        src={hotel.images[0].image.formats.small.url}
                        alt={hotel.images[0].image.alternativeText}
                        layout={'fixed'}
                        width={350}
                        height={260}
                        objectFit={'cover'}
                      />
                    )}
                  {!hotel.images[0].image.formats && (
                    <Image
                      src={hotel.images[0].image.url}
                      alt={hotel.images[0].image.alternativeText}
                      layout={'fixed'}
                      width={350}
                      height={260}
                      objectFit={'cover'}
                    />
                  )}
                </>
              )}
              {!hotel.images[0].image && hotel.images[0].url && (
                <Image
                  src={hotel.images[0].url}
                  alt={''}
                  layout={'fixed'}
                  width={350}
                  height={260}
                  objectFit={'cover'}
                />
              )}
            </div>
          )}
          {gallery && (
            <div className={`gallery ${styles.gallery}`}>
              <Carousel
                slidesToScroll={1}
                slidesToShow={1}
                renderCenterLeftControls={({ previousSlide, currentSlide }) => (
                  <button
                    className={`${styles.chevronLeft} ${
                      currentSlide === 0 ? styles.disabled : ''
                    }`}
                    onClick={previousSlide}
                  >
                    <Chevron />
                  </button>
                )}
                renderCenterRightControls={({
                  nextSlide,
                  currentSlide,
                  slideCount,
                }) => (
                  <button
                    className={`${styles.chevronRight} ${
                      currentSlide === slideCount - 1 ? styles.disabled : ''
                    }`}
                    onClick={nextSlide}
                  >
                    <Chevron />
                  </button>
                )}
                renderBottomCenterControls={null}
                enableKeyboardControls
                cellSpacing={2}
                className={styles.slider}
              >
                {hotel.images.slice(0, 10).map((image, i) => {
                  return (
                    <div className={styles.image} key={i}>
                      {image.image && (
                        <>
                          {image.image.formats &&
                            image.image.formats.hasOwnProperty('medium') && (
                              <Image
                                src={image.image.formats.medium.url}
                                alt={image.image.alternativeText}
                                layout={'responsive'}
                                width={592}
                                height={442}
                                placeholder="blur"
                                blurDataURL={image.image.formats.medium.url}
                              />
                            )}
                          {!image.image.formats && (
                            <Image
                              src={image.image.url}
                              alt={image.image.alternativeText}
                              layout={'responsive'}
                              width={750}
                              height={500}
                              placeholder="blur"
                              blurDataURL={image.image.url}
                            />
                          )}
                        </>
                      )}
                      {!image.image && image.url && (
                        <Image
                          src={image.url}
                          alt=""
                          layout={'responsive'}
                          width={750}
                          height={500}
                          placeholder="blur"
                          blurDataURL={image.url}
                        />
                      )}
                    </div>
                  )
                })}
              </Carousel>
            </div>
          )}
          <div className={`card-inner ${styles.inner}`}>
            <div className={'card-inner-wrapper'}>
              <div className={styles.header}>
                {gallery && (
                  <span className={styles.icon}>
                    {hotel.cardinalPoint === 'NW' && <NWSvg />}
                    {hotel.cardinalPoint === 'W' && <WSvg />}
                    {hotel.cardinalPoint === 'SW' && <SWSvg />}
                    {hotel.cardinalPoint === 'S' && <SSvg />}
                    {hotel.cardinalPoint === 'SE' && <SESvg />}
                    {hotel.cardinalPoint === 'E' && <ESvg />}
                    {hotel.cardinalPoint === 'NE' && <NESvg />}
                    {hotel.cardinalPoint === 'N' && <NSvg />}
                    {hotel.cardinalPoint === 'C' && <CSvg />}
                  </span>
                )}
                {gallery && (
                  <span className={styles.tagline}>{hotel.location.city}</span>
                )}
                {!small && (
                  <div className={'rating'}>
                    {Array.from(Array(hotel.tripadvisor_rating), (e, i) => {
                      return (
                        <React.Fragment key={i}>
                          <DiamondSvg />
                        </React.Fragment>
                      )
                    })}
                    {Array.from(Array(5 - hotel.tripadvisor_rating), (e, i) => {
                      return (
                        <React.Fragment key={i}>
                          <DiamondNoFillSvg />
                        </React.Fragment>
                      )
                    })}
                  </div>
                )}
                {!gallery && hotel.location && (
                  <span className={styles.tagline}>{hotel.location.city}</span>
                )}
              </div>
              <h2>{hotel.name}</h2>
              {!small && (
                <div className={`flex-grow ${styles.innerMiddle}`}>
                  <ul className={styles.details}>
                    <li>
                      <span>Tone</span> {hotel.tone}
                    </li>
                    <li>
                      <span>Vibe</span> {hotel.vibe}
                    </li>
                  </ul>
                  {gallery && !smallGallery && !fullIntro && (
                    <p className={styles.hotelText}>
                      {hotel.standfirst && hotel.standfirst.length > 105
                        ? hotel.standfirst.slice(0, 105) + '...'
                        : hotel.standfirst}{' '}
                      <span onClick={showPopup}>{t('read-more')}</span>
                    </p>
                  )}
                  {fullIntro && (
                    <p className={styles.hotelText}>{hotel.standfirst}</p>
                  )}
                  <div className={`properties ${styles.properties}`}>
                    {hotel.featuredAmenities &&
                      hotel.featuredAmenities.amenities
                        .slice(0, 5)
                        .map((amenity, i) => {
                          return (
                            <React.Fragment key={i}>
                              <Image
                                src={`${amenity.icon.url}`}
                                alt={amenity.friendlyName.replace('WT ', '')}
                                title={amenity.friendlyName.replace('WT ', '')}
                                layout={'fixed'}
                                width={32}
                                height={32}
                              />
                              {gallery && (
                                <span className={'properties-label'}>
                                  {amenity.friendlyName.replace('WT ', '')}
                                </span>
                              )}
                            </React.Fragment>
                          )
                        })}
                  </div>
                </div>
              )}
              {!hideActions && (
                <div className={styles.footer}>
                  <div>
                    {hotel.basePrice > 0 && (
                      <>
                        <span className={styles.label}>{t('from')}</span>
                        <span className={styles.price}>
                          {formatAmountForDisplay(
                            calculateMarkup(
                              hotel.basePrice,
                              settings.baseMarkup,
                              hotel.markup
                            ),
                            currency,
                            settings.exchangeRate
                          )}
                        </span>{' '}
                        / per night
                      </>
                    )}
                    {hotel.basePrice === 0 && showFrom === true && (
                      <>
                        <span className={styles.label}>{t('from')}</span>
                        <span className={styles.price}>
                          {formatAmountForDisplay(
                            calculateMarkup(
                              hotel.fromPrice,
                              settings.baseMarkup,
                              hotel.markup
                            ),
                            currency,
                            settings.exchangeRate
                          )}
                        </span>{' '}
                        / per night
                      </>
                    )}
                    {hotel.basePrice === 0 && showFrom === false && (
                      <span className={styles.price}>Not Available</span>
                    )}
                  </div>

                  {!small && (
                    <div className={styles.actions}>
                      <WishlistFavourite type="hotel" id={hotel.id} />
                      <Button
                        size="small"
                        color="green"
                        href={`/stays/${hotel.slug}`}
                      >
                        Explore
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
HotelCard.propTypes = {
  gallery: PropTypes.bool,
  small: PropTypes.bool,
  hotel: PropTypes.object,
  id: PropTypes.string,
  smallGallery: PropTypes.bool,
  hideActions: PropTypes.bool,
  fullIntro: PropTypes.bool,
  fromPrice: PropTypes.number,
  landscape: PropTypes.bool,
}
export default HotelCard
